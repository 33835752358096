/* eslint-disable */
import React, { Component } from 'react'
import $ from 'jquery'

class Terms extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    if (window.location.hash === '#policy') {
      setTimeout(() => {
        $('#policy-tab')[0].click()
      }, 10)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="terms_container">
          <div className="container info_tab_container my-150">
            <div className="nav_container text-left">
              <ul className="nav nav-tabs row justify-content-center mx-0" id="myTab" role="tablist">
                <li className="nav-item col-4">
                  <a
                    className="nav-link active"
                    id="terms-tab"
                    data-toggle="tab"
                    href="#terms"
                    role="tab"
                    aria-controls="terms"
                    aria-selected="true"
                  >
                    {' '}
                    Terms of Use
                  </a>
                </li>
                <li className="nav-item col-4">
                  <a
                    className="nav-link"
                    id="terms-tab"
                    data-toggle="tab"
                    href="#policy"
                    role="tab"
                    aria-controls="policy"
                    aria-selected="false"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane terms fade show active" id="terms" role="tabpanel" aria-labelledby="terms-tab">
                <h1 className="terms_title text-left mb-50">Terms of Use</h1>
                <p className="terms_date text-left mb-20 font-weight-500">Last Updated on May 29, 2019</p>
                <p className="terms_contents mt-10">
                  These Terms of Service (which, togather with the business Terms below, constitute the{' '}
                  <span style={{ fontWeight: 'bold' }}>"Terms"</span>) are effective immediately for users accessing or
                  using the Service or registering Accounts on or a after March 29, 2019 and will become effective April
                  30, 2019 for users with pre-existing Accounts.
                </p>
                <p className="terms_contents mt-10">
                  The Terms govern your access to and use of our products and service, including those offered through
                  our websites and mobile applications (collectively, the "Service). By accessing or using the Service,
                  you are agreeing to these Terms and concluding a legally binding contract with RiderDash Inc., a
                  Delaware corporation with its headquarters in San Jose, California, if you are a resident of the
                  United States of America or Canada or anywhere else outside the United States of America or Canada,
                  (“RiderDash” means RiderDash Inc. as applicable). Do not access or use the Service if you are
                  unwilling or unable to be bound by the Terms.
                </p>
                <ol className="pl-10" type="1">
                  <li className="terms_subtitle mt-30 mb-20">DEFINITIONS</li>
                  <ol className="pl-10" type="A">
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Parties. “You”</span> and “your” refer to you, as a user of
                      the Service. A <span style={{ fontWeight: 'bold' }}>“user”</span> is someone who accesses or in
                      any way uses the Service. <span style={{ fontWeight: 'bold' }}>“We,” “us,”</span> and 
                      <span style={{ fontWeight: 'bold' }}>“our”</span> refer to RiderDash and its subsidiaries. 
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Content. “Content”</span> means text, images, photos, audio,
                      video, and all other forms of data or communication. 
                      <span style={{ fontWeight: 'bold' }}>“Your Content”</span> means Content that you submit or
                      transmit to, through, or in connection with the Service, such as ratings, reviews, photos, videos,
                      compliments, invitations, check-ins, votes, friending and following activity, direct messages, and
                      information that you contribute to your user profile or suggest for a business page. 
                      <span style={{ fontWeight: 'bold' }}>“User Content”</span> means Content that users submit or
                      transmit to, through, or in connection with the Service. 
                      <span style={{ fontWeight: 'bold' }}>“RiderDash Content”</span> means Content that we create and
                      make available in connection with the Service.{' '}
                      <span style={{ fontWeight: 'bold' }}>“Third Party Content”</span> means Content that originates
                      from parties other than RiderDash or its users, which is made available in connection with the
                      Service. <span style={{ fontWeight: 'bold' }}>“Service Content”</span> means all of the Content
                      that is made available in connection with the Service, including Your Content, User Content,
                      RiderDash Content, and Third Party Content.
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Sites and Accounts. “Consumer Site”</span> means RiderDash’s
                      consumer website directed to users in the United States of America and related domain s and mobile
                      applications, including those directed to users outside the United States of America. 
                      <span style={{ fontWeight: 'bold' }}>“Consumer Account”</span> means the account you create to
                      access or use the Consumer Site. <span style={{ fontWeight: 'bold' }}>“Business Account”</span>
                      means the account you create to access or use the RiderDash for Busine ss Owners website directed
                      to users in the United States of America and related domains and mobile applications, including
                      those directed to users ou tside the United S tates of America.{' '}
                      <span style={{ fontWeight: 'bold' }}>“Account”</span> means any Consumer Account or Business
                      Account.
                    </li>
                  </ol>
                  <li className="terms_subtitle mt-30 mb-20">CHANGES TO THE TERMS</li>
                  <p className="terms_contents mp-10">
                    we may modify the Terms from time to time. The most current version of the Terms will be
                    located here. You understand and agree that your access to or use of the Service is governed by the
                    Terms effective at the time of your access to or use of the Service. If we make material changes to
                    these Terms, we will notify you by email, by posting notice on the Service, and/or by other method
                    prior to the effective date of the changes. We will also indicate at the top of this page the date
                    that such changes were last made. You should revisit these Terms on a regular basis as revised
                    versions will be binding on you. 
                    <span style={{ fontWeight: 'bold' }}>
                      You understand and agree that your continued access to or use of the Service after the effective
                      date of changes to the Terms represents your acceptance of such changes.
                    </span>
                  </p>
                  <li className="terms_subtitle mt-30 mb-20">TRANSLATION</li>
                  <p className="terms_contents mt-10">
                    We may modify the Terms from time to time. The most current version of the Terms will be
                    located here. You understand and agree that your access to or use of the Service is governed by the
                    Terms effective at the time of your access to or use of the Service. If we make material changes to
                    these Terms, we will notify you by email, by posting notice on the Service, and/or by other method
                    prior to the effective date of the changes. We will also indicate at the top of this page the date
                    that such changes were last made. You should revisit these Terms on a regular basis as revised
                    versions will be binding on you. 
                    <span style={{ fontWeight: 'bold' }}>
                      You understand and agree that your continued access to or use of the Service after the effective
                      date of changes to the Terms represents your acceptance of such changes.
                    </span>
                  </p>
                  <li className="terms_subtitle mt-30 mb-20">USING THE SERVICE</li>
                  <ol className="pl-10" type="A">
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Eligibility.</span> To access or use the Service, you must
                      have the requisite power and authority to enter into these Terms. You may not access or use the
                      Service if you are a competitor of RiderDash or if we have previously banned you from the Service
                      or closed your Account. 
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Permission to Use the Service.</span> We grant you permission
                      to use the Service subject to these Terms. Your use of the Service is at your own risk, including
                      the risk that you might be exposed to Content that is offensive, indecent, inaccurate,
                      objectionable, incomplete, fails to provide adequate warning about potential risks or hazards, or
                      is otherwise inappropriate. 
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Service Availability.</span> The Service may be modified,
                      updated, interrupted, suspended or discontinued at any time without notice or liability. 
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Accounts.</span> You must create an Account and provide
                      certain information about yourself in order to use some of the features that are offered through
                      the Service. You are responsible for maintaining the confidentiality of your Account password. You
                      are also responsible for all activities that occur in connection with your Account. You agree to
                      notify us immediately of any unauthorized use of your Account. We reserve the right to close your
                      Account at any time for any or no reason. Your Consumer Account is for your personal,
                      non-commercial use only, and you may not create or use a Consumer Account for anyone other than
                      yourself. We ask that you provide complete and accurate information about yourself when creating
                      an Account in order to bolster your credibility as a contributor to the Service. You may not
                      impersonate someone else, provide an email address other than your own, create multiple Accounts,
                      or transfer your Consumer Account to another person without RiderDash’s prior approval.
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Communications from RiderDash and Others.</span> By accessing
                      or using the Service, you consent to receive communications from other users and RiderDash through
                      the Service, or through any other means such as emails, push notifications, text messages
                      (including SMS and MMS), and phone calls. These communications may promote RiderDash or businesses
                      listed on RiderDash, and may be initiated by RiderDash, businesses listed on RiderDash, or other
                      users. You further understand that communications may be sent using an automatic telephone dialing
                      system, and that you may be charged by your phone carrier for certain communications such as SMS
                      messages or phone calls. You agree to notify us immediately if the phone number(s) you have
                      provided to us have been changed or disconnected. Please note that any communications, including
                      phone calls, with RiderDash may be monitored and recorded for quality purposes.
                    </li>
                  </ol>
                  <li className="terms_subtitle mt-30 mb-20">CONTENT</li>
                  <ol className="pl-10" type="A">
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Responsibility for Your Content.</span> You alone are
                      responsible for Your Content, and once posted to RiderDash, it cannot always be withdrawn. You
                      assume all risks associated with Your Content, including anyone’s reliance on its quality,
                      accuracy, or reliability, and any risks associated with personal information you disclose. You
                      represent that you own or have the necessary permissions to use and authorize the use of Your
                      Content as described herein. You may not imply that Your Content is in any way sponsored or
                      endorsed by RiderDash.
                      <br />
                      You may expose yourself to liability if, for example, Your Content contains material that is
                      false, intentionally misleading, or defamatory; violates any third-party right, including any
                      copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any
                      other intellectual property or proprietary right; contains material that is unlawful, including
                      illegal hate speech or pornography; exploits or otherwise harms minors; violates or advocates the
                      violation of any law or regulation; or violates these Terms.
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Our Right to Use Your Content.</span> We may use Your Content
                      in a number of different ways, including by publicly displaying it, reformatting it, incorporating
                      it into advertisements and other works, creating derivative works from it, promoting it,
                      distributing it, and allowing others to do the same in connection with their own websites and
                      media platforms (“Other Media”). As such, you hereby irrevocably grant us world-wide, perpetual,
                      non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use Your Content
                      for any purpose. Please note that you also irrevocably grant the users of the Service and any
                      Other Media the right to access Your Content in connection with their use of the Service and any
                      Other Media. Finally, you irrevocably waive, and cause to be waived, against RiderDash and its
                      users any claims and assertions of moral rights or attribution with respect to Your Content. By
                      “use” we mean use, copy, publicly perform and display, reproduce, distribute, modify, translate,
                      remove, analyze, commercialize, and prepare derivative works of Your Content.
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Ownership.</span> As between you and RiderDash, you own Your
                      Content. We own the RiderDash Content, including but not limited to visual interfaces, interactive
                      features, graphics, design, compilation (including, but not limited to, our selection,
                      coordination, and arrangement of User Content and other Service Content), computer code, products,
                      software, aggregate user review ratings, and all other elements and components of the Service
                      excluding Your Content, User Content and Third Party Content. We also own the copyrights,
                      trademarks, service marks, trade names, trade secrets, and other intellectual and proprietary
                      rights throughout the world associated with the RiderDash Content and the Service, which are
                      protected by copyright, trade dress, patent, trademark, and trade secret laws and all other
                      applicable intellectual and proprietary rights and laws. As such, you may not sell, license, copy,
                      publish, modify, reproduce, distribute, create derivative works or adaptations of, publicly
                      display or in any way use or exploit any of the RiderDash Content in whole or in part except as
                      expressly authorized by us. Except as expressly and unambiguously provided herein, we do not grant
                      you any express or implied rights, and all rights in and to the Service and the RiderDash Content
                      are retained by us. 
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Advertising.</span> RiderDash and its licensees may publicly
                      display advertisements, paid content, and other information nearby or in association with Your
                      Content. You are not entitled to any compensation for such advertisements. The manner, mode and
                      extent of such advertising are subject to change without specific notice to you. 
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Other.</span> User Content (including any that may have been
                      created by users employed or contracted by RiderDash) does not necessarily reflect the opinion of
                      RiderDash. We reserve the right to remove, screen, edit, or reinstate User Content at our sole
                      discretion for any reason or no reason, and without notice to you. For example, we may remove a
                      review if we believe it violates our Content Guidelines. Except as described in
                      RiderDash’s Privacy Policy or otherwise required by law, we have no obligation to retain or
                      provide you with copies o f Your Content, and we do not guarantee any confidentiality with respect
                      to Your Content.
                    </li>
                  </ol>
                  <li className="terms_subtitle mt-30 mb-20">BOOKING AND TRAN SACTING</li>
                  <ol className="pl-10" type="A">
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Generally.</span> You can access features through the Service
                      that allow you to book or transact online with local businesses. These features may be provided by
                      RiderDash’s third-party partners, including through iframes or similar formats, and their use may
                      be governed by different or additional terms presented to you as part of the booking or
                      transaction process. Please note that such third-party partners and/or the transacting local
                      businesses themselves are responsible for fulfilling such bookings and transactions. 
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Payments and Cancellations.</span> You may be required to
                      provide your credit card information to confirm a booking, and will be charged any applicable
                      fees, including cancellation or no-show fees in accordance with the transacting local business’s
                      cancellation policy provided at the time of booking. You agree that RiderDash may facilitate any
                      such payments and charges on behalf of the transacting local business. 
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Coupons.</span> Any coupons that RiderDash might issue for
                      use in connection with the Service are non-transferable (unless required by law), not redeemable
                      for cash or any other consideration, and automatically expire thirty (30) days after the issue
                      date unless otherwise specified. If your Account is terminated you will not be able to use any
                      unexpired and unused coupons, and any such coupons will automatically terminate and cannot be
                      redeemed unless required by law.
                    </li>
                  </ol>
                  <li className="terms_subtitle mt-30 mb-20">REPRESENTATIONS AND WARRANTIES</li>
                  <p className="terms_contents mt-10">
                    We are under no obligation to enforce the Terms on your behalf against another user. While we
                    encourage you to let us know if you believe another user has violated the Terms, we reserve the
                    right to investigate and take appropriate action at our sole discretion.
                  </p>
                  <ol className="pl-10" type="A">
                    <li className="terms_contents mt-10">You represent and warrant that:</li>
                    <ol className="pl-10" type="i">
                      <li className="terms_contents mt-10">You have read and understood our Content Guidelines; </li>
                      <li className="terms_contents">
                        You have rea d and understood o ur Privacy Policy. If you use the Service outside of the United
                        States of America, you consent to having your personal data transferred to and processed in the
                        United States of America; and 
                      </li>
                      <li className="terms_contents">
                        Prior to attending any event listed on the Service, you have read and agree to our Event Terms
                        and Conditions. 
                      </li>
                    </ol>
                    <li className="terms_contents mt-10">
                      You also represent and warrant that you will not, and will not assist, encourage, or enable others
                      to use the Service to:
                    </li>
                    <ol className="pl-10" type="i">
                      <li className="terms_contents mt-10">
                        Violate our Terms, including the Content Guidelines and Event Terms and Conditions;
                      </li>
                      <li className="terms_contents">
                        Post any fake or defamatory review, trade reviews with others, or compensate someone or be
                        compensated to post, refrain from posting, or remove a review;
                      </li>
                      <li className="terms_contents">
                        Violate any third party’s rights, including any breach of confidence, copyright, trademark,
                        patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual
                        property or proprietary right;
                      </li>
                      <li className="terms_contents">
                        Threaten, stalk, harm, or harass others, or promote bigotry or discrimination;
                      </li>
                      <li className="terms_contents">
                        Promote a business or other commercial venture or event, or otherwise use the Service for
                        commercial purposes, except in connection with a Business Account in accordance with the
                        Business Terms;
                      </li>
                      <li className="terms_contents">
                        Send bulk emails, surveys, or other mass messaging, whether commercial in nature or not; engage
                        in keyword spamming, or otherwise attempt to manipulate the Service’s search results, review
                        Recommendation Software (as defined in the Business Terms below), or any third party website;
                      </li>
                      <li className="terms_contents">
                        Solicit personal information from minors, or submit or transmit pornography;
                      </li>
                      <li className="terms_contents">Violate any applicable law;</li>
                      <li className="terms_contents">
                        Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or
                        adaptations of, publicly display, sell, trade, or in any way exploit the Service or Service
                        Content (other than Your Content), except as expressly authorized by RiderDash;
                      </li>
                      <li className="terms_contents">
                        Use any robot, spider, Service search/retrieval application, or other automated device, process
                        or means to access, retrieve, scrape, or index any portion of the Service or any Service
                        Content;
                      </li>
                      <li className="terms_contents">
                        Reverse engineer any portion of the Service, unless applicable law prohibits this restriction,
                        in which case you agree to provide us with 30 days’ prior written notice here; 
                      </li>
                      <li className="terms_contents">
                        Remove or modify any copyright, trademark or other proprietary rights notice that appears on any
                        portion of the Service or on any materials printed or copied from the Service;
                      </li>{' '}
                      <li className="terms_contents">Record, process, or mine information about users;</li>
                      <li className="terms_contents">
                        Access, retrieve or index any portion of the Service for purposes of constructing or populating
                        a searchable database of business reviews;
                      </li>
                      <li className="terms_contents">Reformat or frame any portion of the Service;</li>
                      <li className="terms_contents">
                        Take any action that imposes, or may impose, in our sole discretion, an unreasonable or
                        disproportionately large load on RiderDash’s technology infrastructure or otherwise make
                        excessive traffic demands of the Service;
                      </li>
                      <li className="terms_contents">
                        Attempt to gain unauthorized access to the Service, Accounts, computer systems or networks
                        connected to the Service through hacking, password mining or any other means;
                      </li>
                      <li className="terms_contents">
                        Use the Service or any Service Content to transmit any computer viruses, worms, defects, Trojan
                        horses, malicious code, spyware, malware or other items of a destructive or harmful nature;
                      </li>{' '}
                      <li className="terms_contents">
                        Use any device, software or routine that interferes with the proper working of the Service, or
                        otherwise attempt to interfere with the proper working of the Service;
                      </li>
                      <li className="terms_contents">
                        Use the Service to violate the security of any computer network, crack passwords or security
                        encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, the
                        Service or Service Content; or
                      </li>
                      <li className="terms_contents">
                        Remove, circumvent, disable, damage or otherwise interfere with any security-related features of
                        the Service, features that prevent or restrict the use or copying of Service Content, or
                        features that enforce limitations on the use of the Service.
                      </li>
                    </ol>
                  </ol>
                  <li className="terms_subtitle mt-30 mb-20">ADDITIONAL POLICIES AND TERMS</li>
                  <ol className="pl-10" type="A">
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Copyright And Trademark Disputes.</span> You agree to follow
                      our Infringement Policy in notifying us about copyright and trademark disputes concerning User
                      Content. You agree we may forward any notification sent pursuant to our Infringement Policy to the
                      user who submitted the User Content at issue. 
                    </li>
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Additional Terms.</span> Your use of the Service is subject
                      to any and all additional terms, policies, rules, or guidelines that we may post on or link to
                      from the Service (the “Additional Terms”). All such Additional Terms are hereby incorporated by
                      reference into, and made a part of, these Terms. If you have a Business Account, the Business
                      Terms provided below apply to you.
                    </li>
                  </ol>
                  <li className="terms_subtitle mt-30 mb-20">SUGGESTIONS AND IMPROVEMENTS</li>
                  <p className="terms_contents mt-10">
                    By sending us any ideas, suggestions, documents or proposals (
                    <span style={{ fontWeight: 'bold' }}>“Feedback”</span>), you agree that (i) your Feedback does not
                    contain any third party confidential or proprietary information, (ii) we are under no obligation of
                    confidentiality, express or implied, with respect to the Feedback, (iii) we may have something
                    similar to the Feedback already under consideration or in development, (iv) we have no obligation to
                    review, consider, or implement the Feedback, or to return to you all or part of the Feedback, and
                    (v) you grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide, assignable,
                    sublicensable, transferable license to use, modify, prepare derivative works of, publish, distribute
                    and sublicense the Feedback, and you irrevocably waive, and cause to be waived, against RiderDash
                    and its users any claims and assertions of any moral rights contained in such Feedback.
                  </p>
                  <li className="terms_subtitle mt-30 mb-20">THIRD PARTY CONTENT AND SERVICES</li>
                  <p className="terms_contents mt-10">
                    The Service may host Third Party Content, or include links to other websites or applications (each,
                    a <span style={{ fontWeight: 'bold' }}>“Third Party Service”</span>). We do not control or endorse
                    any Third Party Content or Third Party Service. You agree that we are not responsible for the
                    availability, accuracy, or content of any such Third Party Content or Third Party Service. Your use
                    of and reliance on any Third Party Content or Third Party Service is at your own risk. Some of the
                    services made available through the Service and Third Party Services may be subject to additional
                    third party terms of service, privacy policies, licensing terms and disclosures, and other terms,
                    conditions, and policies, including without limitation the ones posted here. It is your
                    responsibility to familiarize yourself with any such applicable third party terms.
                  </p>
                  <li className="terms_subtitle mt-30 mb-20">INDEMNITY</li>
                  <p className="terms_contents mt-10">
                    You agree to indemnify, defend, and hold harmless RiderDash, its parents, subsidiaries, affiliates,
                    any related companies, suppliers, licensors and partners, and the officers, directors, employees,
                    agents, contractors and representatives of each of them (collectively, the{' '}
                    <span style={{ fontWeight: 'bold' }}>“RiderDash Entities”</span>) from and against any and all third
                    party claims, actions, demands, losses, damages, costs, liabilities and expenses (including but not
                    limited to attorneys’ fees and court costs) arising out of or relating to: (i) your access to or use
                    of the Service, including Your Content, (ii) your violation of the Terms, (iii) your breach of your
                    representations and warranties provided under these Terms, (iv) any products or services purchased
                    or obtained by you in connection with the Service, (v) your products or services, or the marketing
                    or provision thereof to end users, or (vi) the infringement by you, or any third party using your
                    Account, of any intellectual property or other right of any person or entity. RiderDash reserves the
                    right, at your expense, to assume the exclusive defense and control of any matter for which you are
                    required to indemnify us and you agree to cooperate with our defense of these claims. You agree not
                    to settle any such matter without the prior written consent of RiderDash. RiderDash will use
                    reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
                  </p>
                  <li className="terms_subtitle mt-30 mb-20">DISCLAIMERS AND LIMITATIONS OF LIABILITY</li>
                  <p className="terms_contents mt-10 font-weight-500">
                    PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE RIDERDASH ENTITIES TO YOU.
                    EACH OF THE SUBSECTIONS BELOW ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
                    NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED. BY
                    ACCESSING OR USING THE SERVICE, YOU REPRESENT THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE
                    TERMS, INCLUDING THIS SECTION. YOU ARE GIVING UP SUBSTANTIAL LEGAL RIGHTS BY AGREEING TO THESE
                    TERMS. 
                  </p>
                  <ol className="pl-10" type="A">
                    <li className="terms_contents mt-10">
                      THE SERVICE AND SERVICE CONTENT ARE MADE AVAILABLE TO YOU ON AN “AS IS”, “WITH ALL FAULTS” AND “AS
                      AVAILABLE” BASIS, WITH THE EXPRESS UNDERSTANDING THAT THE RIDERDASH ENTITIES MAY NOT MONITOR,
                      CONTROL, OR VET USER CONTENT OR THIRD PARTY CONTENT. AS SUCH, YOUR USE OF THE SERVICE IS AT YOUR
                      OWN DISCRETION AND RISK. THE RIDERDASH ENTITIES MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY,
                      COMPLETENESS, ACCURACY, OR RELIABILITY OF THE SERVICE, ITS SAFETY OR SECURITY, OR THE SERVICE
                      CONTENT. ACCORDINGLY, THE RIDERDASH ENTITIES ARE NOT LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS
                      OR DAMAGE THAT MIGHT ARISE, FOR EXAMPLE, FROM THE SERVICE’S INOPERABILITY, DEPLETION OF BATTERY
                      POWER OR OTHER IMPAIRMENT OF DEVICES USED TO ACCESS THE SERVICE, SERVICE UNAVAILABILITY, SECURITY
                      VULNERABILITIES OR FROM YOUR RELIANCE ON THE QUALITY, ACCURACY, OR RELIABILITY OF THE BUSINESS
                      LISTINGS, RATINGS, REVIEWS (INCLUDING THEIR CONTENT OR OMISSION OF CONTENT, ORDER, AND DISPLAY),
                      METRICS OR OTHER CONTENT FOUND ON, USED ON, OR MADE AVAILABLE THROUGH THE SERVICE.
                    </li>
                    <li className="terms_contents mt-10">
                      THE RIDERDASH ENTITIES MAKE NO C LAIMS OR PROMISES WITH RESPECT TO ANY THIRD PARTY, SUCH AS THE
                      BUSINESSES OR ADVERTISERS LISTED ON THE SERVICE OR THAT OFFER GOODS OR SERVICES THROUGH THE
                      SERVICE, OR THE SERVICE’S USERS. ACCORDINGLY, THE RIDERDASH ENTITIES ARE NOT LIABLE TO YOU FOR ANY
                      PERSONAL INJURY, LOSS OR DAMAGE THAT MIGHT ARISE FROM ANY SUCH THIRD PARTY’S ACTIONS OR OMISSIONS,
                      INCLUDING, FOR EXAMPLE, IF ANOTHER USER OR BUSINESS MISUSES YOUR CONTENT, IDENTITY OR PERSONAL
                      INFORMATION, OR IF YOU HAVE A NEGATIVE EXPERIENCE WITH ONE OF THE BUSINESSES OR ADVERTISERS LISTED
                      OR FEATURED ON THE SERVICE. YOUR PURCHASE AND USE OF PRODUCTS OR SERVICES OFFERED BY THIRD PARTIES
                      THROUGH THE SERVICE IS AT YOUR OWN DISCRETION AND RISK.
                    </li>
                    <li className="terms_contents mt-10">
                      YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SERVICE, RELATED
                      SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR
                      USE OF THE SERVICE.
                    </li>
                    <li className="terms_contents mt-10">
                      THE RIDERDASH ENTITIES’ MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER
                      IN CONNECTION WITH THE SERVICE OR THESE TERMS IS LIMITED TO THE GREATER OF (i) THE AMOUNT PAID, IF
                      ANY, BY YOU TO THE RIDERDASH ENTITIES IN CONNECTION WITH THE SERVICE IN THE 12 MONTHS PRIOR TO THE
                      ACTION GIVING RISE TO LIABILITY, OR (ii) $100.
                    </li>
                    <li className="terms_contents mt-10">
                      THE RIDERDASH ENTITIES WILL NOT BE LIABLE FOR ANY (i) INDIRECT, SPE CIAL, INCIDENTAL, PUNITIVE,
                      EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, (ii) LOSS OF PROFITS OR REVENUE, (iii) BUSINESS
                      INTERRUPTION, (iv) REPUTATIONAL HARM, (v) LOSS OF INFORMATION OR DATA; OR (vi) LIABILITY WITH
                      RESPECT TO A CONSUMER ALERT POSTED ON ANY RIDERDASH BUSINESS PAGES FOR YOUR BUSINESS.
                    </li>
                  </ol>
                  <li className="terms_subtitle mt-30 mb-20">CHOICE OF LAW AND VENUE</li>
                  <ol className="pl-10" type="A">
                    <li className="terms_contents mt-10">
                      If you are a resident of the United States or Canada:California law will govern these Terms, as
                      well as any claim, cause of action or dispute that might arise between you and RiderDash (a 
                      <span style={{ fontWeight: 'bold' }}>“Claim”</span>), without regard to conflict of law
                      provisions. FOR ANY CLAIM BROUGHT BY EITHER PARTY, YOU AGREE TO SUBMIT AND CONSENT TO THE PERSONAL
                      AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE VENUE OF, THE STATE AND FEDERAL COURTS LOCATED
                      WITHIN SANTA CLARA COUNTY, CALIFORNIA.
                    </li>
                    <li className="terms_contents mt-10">
                      You agree that any subpoena, third-party discovery request, or other third-party process directed
                      to RiderDash must issue from, or be domesticated by, the state or federal courts located within
                      Santa Clara County, California and you agree to submit to the personal jurisdiction of each of
                      these c ourts for any related proceedings.
                    </li>
                    <li className="terms_contents mt-10">
                      Attorneys’ fees and costs may be awarded against any party that pursues any Claim or Third Party
                      Process in a manner contrary to this Section, provided that party does not promptly withdraw the
                      Claim or Third Party Process once this Section is brought to its attention.
                    </li>
                  </ol>
                  <li className="terms_subtitle mt-30 mb-20">TERMINATION</li>
                  <ol className="pl-10" type="A">
                    <li className="terms_contents mt-10">
                      You may terminate the Terms at any time by closing your Account, discontinuing any access to or
                      use of the Service, and providing RiderDash with a notice of termination. 
                    </li>
                    <li className="terms_contents mt-10">
                      We may close your Account, suspend your ability to use certain portions of the Service, terminate
                      any license or permission granted to you hereunder, and/or ban you altogether from the Service for
                      any or no reason, and without notice or liability of any kind. Any such action could prevent you
                      from accessing your Account, the Service, Your Content, Service Content, or any other related
                      information.
                    </li>
                    <li className="terms_contents mt-10">
                      In the event of any termination of these Terms, whether by you or us, Sections 1, 3, 5, 7, 10–14
                      of the Terms of Service will continue in full force and effect.
                    </li>
                  </ol>
                  <li className="terms_subtitle mt-30 mb-20">GENERAL TERMS</li>
                  <ol className="pl-10" type="A">
                    <li className="terms_contents mt-10">
                      We reserve the right to modify, update, or discontinue the Service at our sole discretion, at any
                      time, for any or no reason, and without notice or liability.
                    </li>
                    <li className="terms_contents mt-10">
                      Except as otherwise stated in Section 10 above, nothing herein is intended, nor will be deemed, to
                      confer rights or remedies upon any third party.
                    </li>
                    <li className="terms_contents mt-10">
                      The Terms contain the entire agreement between you and us regarding the use of the Service, and
                      supersede any prior agreement between you and us on such subject matter. The parties acknowledge
                      that no r eliance is placed on any representation made but not expressly contained in these Terms.
                    </li>
                    <li className="terms_contents mt-10">
                      Any failure on RiderDash’s part to exercise or enforce any right or provision of the Terms does
                      not constitute a waiver of such right or provision. The failure of either party to exercise in any
                      respect any right provided for herein shall not be deemed a waiver of any further rights
                      hereunder. The Terms may not be waived, except pursuant to a writing executed by RiderDash.
                    </li>
                    <li className="terms_contents mt-10">
                      If any provision of the Terms is found to be unenforceable or invalid by a court of competent
                      jurisdiction, then only that provision shall be modified to reflect the parties’ intention or
                      eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force
                      and effect and enforceable.
                    </li>
                    <li className="terms_contents mt-10">
                      The Terms, and any rights or obligations hereunder, are not assignable, transferable or
                      sublicensable by you except with RiderDash’s prior written consent, but may be assigned or
                      transferred by us without restriction. Any attempted assignment by you shall violate these Terms
                      and be void.
                    </li>
                    <li className="terms_contents mt-10">
                      You agree that no joint venture, partnership, employment, agency, special or fiduciary
                      relationship exists between you and RiderDash as a result of these Terms or your use of the
                      Service.
                    </li>
                    <li className="terms_contents mt-10">
                      The section titles in the Terms are for convenience only and have no legal or contractual effect.
                    </li>
                  </ol>
                  <p className="terms_contents mt-30">
                    Copyright © 2019 RiderDash Inc., 3003 N 1st St., Unit 221, San Jose, CA 95134, U.S.A.
                  </p>

                  <h1 className="terms_title text-left mb-50 mt-150">ADDITIONAL TERMS FOR BUSINESS ACCOUNTS</h1>
                  <p className="terms_date text-left mb-20 font-weight-500">Last Updated on May 29, 2019</p>
                  <p className="terms_contents text-left mt-10">
                    The following terms (<span style={{ fontWeight: 'bold' }}>“Business Terms”</span>), in addition to
                    the Terms of Service above, govern your access to and use of your Business Account. In the event of
                    any conflict between these Business Terms and the Terms of Service, the Business Terms apply. If you
                    have purchased products or services from RiderDash on behalf of your business (e.g., advertising or
                    business tools), the terms of that purchase apply in the event of any conflict with these Business
                    Terms. Capitalized words used but not defined in these Business Terms have the meanings described in
                    the Terms of Service. By creating, accessing, or using your Business Account, you are agreeing to
                    these Business Terms and concluding a legally binding contract with RiderDash. You are not
                    authorized to create, access, or use a Business Account if you do not agree to these Business Terms.
                    Please read these Business Terms carefully as they require the use of arbitration on an individual
                    basis to resolve disputes, rather than trials or class actions, and also limit the remedies
                    available to you in the event of a dispute.
                  </p>
                  <p className="terms_contents mt-10">
                    In the event of any termination of these Business Terms, whether by you or us, these Business Terms
                    in their entirety will continue in full force and effect.
                  </p>
                  <ol className="pl-10" type="1">
                    <li className="terms_subtitle mt-30 mb-20">REPRESENTATIONS AND WARRANTIES</li>
                    <ol className="pl-10" type="A">
                      <li className="terms_contents mt-10">You represent and warrant that:</li>
                      <ol className="pl-10" type="i">
                        <li className="terms_contents mt-10">
                          you have the authority to act on behalf of the business or businesses associated with your
                          Business Account and bind any such business to the Business Terms (such business or
                          businesses, your <span style={{ fontWeight: 'bold' }}>“Business”</span>); 
                        </li>
                        <li className="terms_contents">
                          your access to or use of the Business Site will only be in your capacity as an authorized
                          representative of your Business;
                        </li>
                        <li className="terms_contents">
                          you will not use the Consumer Site for business activities, including but not limited to
                          flagging reviews or messaging people who have reviewed your Business;
                        </li>
                        <li className="terms_contents">
                          your Business complies with applicable laws and does not offer or advertise illegal products
                          and/or services for lease or sale;
                        </li>
                        <li className="terms_contents">
                          you agree that we may contact you, including by phone or email, using the contact information
                          you provide us, make publicly available, or that we have on record for your business, and that
                          our calls with you may be monitored and recorded for quality purposes; and
                        </li>
                        <li className="terms_contents">
                          you understand and acknowledge that non-disparagement clauses in certain consumer contracts,
                          such as clauses that seek to restrict or prohibit reviews (including provisions that penalize
                          consumers for posting reviews) about your Business, are illegal under California law (Cal.
                          Civil Code § 1670.8) and under the federal Consumer Review Fairness Act (15 U.S. Code § 45b)
                          and you agree that you will not include such clauses in your consumer contracts, or otherwise
                          attempt to enforce non-disparagement or ‘gag’ clauses against consumers under any
                          circumstances. 
                        </li>
                      </ol>
                      <li className="terms_contents mt-10">
                        You also represent and warrant that you will not, and will not authorize or induce any other
                        party, to: 
                      </li>
                      <ol className="pl-10" type="i">
                        <li className="terms_contents mt-10">
                          offer incentives of any kind, such as discounts, freebies, refunds, gift cards, contest
                          entries, offers, or deals in exchange for the posting of reviews of your Business, or to
                          prevent or remove reviews, and you understand and acknowledge that RiderDash, through its
                          Consumer Alerts, may publicly notify consumers about such incentives and other attempts to
                          obtain, prevent, or remove reviews;
                        </li>
                        <li className="terms_contents">solicit or ask for reviews from your customers;</li>
                        <li className="terms_contents">
                          write reviews or vote on Content (e.g., voting user reviews as useful, funny, or cool) for
                          your Business or your Business’s competitors;
                        </li>
                        <li className="terms_contents">
                          pay or induce any third party to post, refrain from posting, or remove reviews, or otherwise
                          attempt to circumvent RiderDash’s Recommendation Software (defined below) and fraud detection
                          systems;
                        </li>
                        <li className="terms_contents">
                          attempt to generate automated, fraudulent, or otherwise invalid ad impressions, inquiries,
                          conversions, ad clicks, or other actions;
                        </li>
                        <li className="terms_contents">
                          use any automated means or form of scraping or data extraction to access, query or otherwise
                          collect RiderDash data, content and/or reviews from the Consumer Site or the Business Site,
                          except as expressly permitted by RiderDash;
                        </li>
                        <li className="terms_contents">
                          use any RiderDash trademarks in any manner without RiderDash’s prior written consent; or
                        </li>
                        <li className="terms_contents">
                          misrepresent your identity or affiliation to anyone in connection with RiderDash.
                        </li>
                      </ol>
                      <li className="terms_contents mt-10">
                        You understand and acknowledge that RiderDash allows consumers to post Content about your
                        Business, including photos, ratings, and reviews. You understand and acknowledge that RiderDash
                        employs automated software in an effort to showcase the most reliable and useful reviews while
                        displaying other reviews less prominently (
                        <span style={{ fontWeight: 'bold' }}>“Recommendation Software”</span>). You understand and
                        acknowledge that while RiderDash uses its Recommendation Software to identify potentially less
                        helpful reviews, the Recommendation Software may sometimes suppress legitimate reviews or fail
                        to detect illegitimate reviews. You understand and acknowledge that any purchase of advertising
                        or other paid features from RiderDash will not influence the Recommendation Software or
                        otherwise allow or enable You, directly or indirectly, to alter reviews or impact whether,
                        where, or how reviews appear on RiderDash.
                      </li>
                      <p className="terms_contents mt-30 font-weight-500">
                        THE FOLLOWING SECTIONS 2 AND 3 APPLY IF YOU ARE A RESIDENT OF THE UNITED STATES OR CANADA ONLY:
                      </p>
                    </ol>
                    <li className="terms_subtitle mt-30 mb-20">DISCLAIMERS AND LIMITATIONS OF LIABILITY</li>
                    <p className="terms_contents mt-10 font-weight-500">
                      PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE RIDERDASH ENTITIES TO YOU.
                      FOR CLARITY, THE BELOW APPLIES IN ADDITION TO THE DISCLAIMERS AND LIMITATIONS OF LIABILITY
                      DETAILED IN SECTION 12 OF THE TERMS.
                    </p>
                    <p className="terms_contents text-left mt-10">
                      The federal Communications Decency Act (47 U.S. Code § 230) limits the liability of interactive
                      computer services, like RiderDash, for their role in publishing third-party Content, including
                      consumer reviews. Additionally, anti-SLAPP laws, such as Cal. Civ. Proc. Code § 425.16 in
                      California, may require you to pay RiderDash's attorneys' fees if you attempt to impose such
                      liability on RiderDash through legal proceedings.
                    </p>
                    <li className="terms_subtitle mt-30 mb-20">DISPUTES</li>
                    <p className="terms_contents mt-10">
                      Any controversy or claim arising out of or relating to: (a) these Terms, or the breach thereof; or
                      (b) your access to or use of your Business Account Business Site; (each such controversy or claim,
                      a <span style={{ fontWeight: 'bold' }}>“Claim”</span>), shall be settled by arbitration
                      administered by the American Arbitration Association in accordance with its Commercial Arbitration
                      Rules and judgment on the award rendered by the arbitrator may be entered in any court having
                      jurisdiction thereof. The arbitrator will issue a reasoned award in writing, including all
                      findings of fact and law upon which the award was made. The arbitrator will not have the power to
                      commit errors of law, and the award may be vacated or corrected through judicial review by a court
                      of competent jurisdiction under the California Arbitration Act for any such error.
                    </p>
                    <p className="terms_contents">
                      Claims shall be heard by a single arbitrator. Arbitrations will be held in San Jose, California,
                      but the parties may choose for themselves whether to appear in person, by phone, or through the
                      submission of documents. The arbitration shall be governed by the laws of the State of California.
                      The prevailing party shall be entitled to an award of reasonable attorneys’ fees.
                    </p>
                    <p className="terms_contents mt-10">
                      NOTWITHSTANDING THE FOREGOING, FOR ANY CLAIM THAT IS NOT SUBJECT TO ARBITRATION, YOU AGREE TO
                      SUBMIT AND CONSENT TO THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE VENUE OF, THE
                      STATE AND FEDERAL COURTS LOCATED WITHIN SANTA CLARA COUNTY, CALIFORNIA, WHICH IS THE PLACE OF
                      PERFORMANCE OF THESE BUSINESS TERMS.
                    </p>
                    <p className="terms_contents mt-10">
                      YOU AND RIDERDASH AGREE THAT EACH MAY BRING OR PARTICIPATE IN CLAIMS AGAINST THE OTHER ONLY IN
                      THEIR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
                      CLASS OR REPRESENTATIVE PROCEEDING. UNLESS BOTH YOU AND RIDERDASH AGREE OTHERWISE, THE ARBITRATOR
                      MAY NOT CONSOLIDATE OR JOIN THE CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY SITUATED,
                      AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. IF A CLAIM
                      IMPLICATES THIS SECTION, AND THIS SECTION IS FOUND TO BE INVALID, UNENFORCEABLE OR ILLEGAL BY A
                      COURT, SUCH CLAIM MUST BE ADJUDICATED BY A COURT AND NOT BY AN ARBITRATOR
                    </p>
                    <p className="terms_contents mt-30">
                      Copyright © 2019 RiderDash Inc., 3003 N 1st St., Unit 221, San Jose, CA 95134, U.S.A.
                    </p>
                  </ol>
                </ol>
              </div>
              <div className="tab-pane policy fade" id="policy" role="tabpanel" aria-labelledby="policy-tab">
                <h1 className="terms_title text-left mb-50">Privacy Policy</h1>
                <p className="terms_date text-left mb-20 font-weight-500">Last Updated on March 29, 2019</p>
                <p className="terms_contents mt-10">
                  This Privacy Policy is effective March 29, 2019 for all users.This Privacy Policy describes our
                  policies on the collection, use, and disclosure of information about you in connection with your use
                  of our services, including those offered through our websites, emails, and mobile applications
                  (collectively, the "Service"). The terms "we", "us", and "RiderDash" refer to RiderDash Inc., a
                  Delaware corporation with its headquarters in San Jos e, California.
                </p>
                <p className="terms_contents mt-10">
                  When you use the Service, you consent to our collection, use, and disclosure of information about you
                  as described in this Privacy Policy. We may translate this Privacy Policy into other languages for
                  your convenience. Nevertheless, the English version governs your relationship with RiderDash, and any
                  inconsistencies among the different versions will be resolved in favor of the English version.
                </p>
                <p className="terms_contents font-weight-700 mt-30 mb-20">TABLE OF CONTENTS</p>
                <ol className="pl-10" type="1">
                  <li className="terms_contents">
                    <a className="a-link" href="#Information">
                      Information We Collect and How We Use It
                    </a>
                  </li>
                  <li className="terms_contents">
                    <a className="a-link" href="#Cookies">
                      Cookies
                    </a>
                  </li>
                  <li className="terms_contents">
                    <a className="a-link" href="#Parties">
                      Third Parties
                    </a>
                  </li>
                  <li className="terms_contents">
                    <a className="a-link" href="#Data">
                      Controlling Your Personal Data
                    </a>
                  </li>
                  <li className="terms_contents">
                    <a className="a-link" href="#Termination">
                      Data Retention and Account Termination
                    </a>
                  </li>
                  <li className="terms_contents">
                    <a className="a-link" href="#Children">
                      Children
                    </a>
                  </li>
                  <li className="terms_contents">
                    <a className="a-link" href="#Security">
                      Security
                    </a>
                  </li>
                  <li className="terms_contents">
                    <a className="a-link" href="#Contact">
                      Contact Information
                    </a>
                  </li>
                  <li className="terms_contents">
                    <a className="a-link" href="#Policy">
                      Modifications to This Privacy Policy
                    </a>
                  </li>
                  <li className="terms_contents">
                    <a className="a-link" href="#Rights">
                      California Residents: Your California Privacy Rights
                    </a>
                  </li>
                  <li className="terms_contents">
                    <a className="a-link" href="#Transfer">
                      International Data Transfer
                    </a>
                  </li>
                </ol>
                <ol className="pl-10" type="1">
                  <li id="Information" className="terms_subtitle mt-30 mb-20">
                    INFORMATION WE COLLECT AND HOW WE USE IT
                  </li>
                  <p className="terms_contents mt-10">
                    We may collect and store information about you in connection with your use of the Service, including
                    any information you transmit to or through the Service. We use that information to provide the
                    Service’s functionality, fulfill your requests, improve the Service’s quality, engage in research
                    and analysis relating to the Service, personalize your experience, track usage of the Service,
                    provide feedback to third party businesses that are listed on the Service, display relevant
                    advertising, market the Service, provide customer support, message you, back up our systems, allow
                    for disaster recovery, enhance the security of the Service, and comply with legal obligations. Even
                    when we do not retain such information, it still must be transmitted to our servers initially and
                    stored long enough to process.
                  </p>
                  <p className="terms_contents mt-10">Please also note:</p>
                  <ol className="pl-10" type="a">
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Account Information:</span> If you create a RiderDash
                      account, we may store and use the information you provide during that process, such as your full
                      name, email address, zip code, physical address, and other information you may provide with your
                      account, such as your gender, phone number, and birth date. We may publicly display your first
                      name and last initial, as well as any photo or other content you submit through the registration
                      process, as part of your account profile. You can modify some of the information associated with
                      your account through your 
                      <a className="a-link" href="">
                        account settings
                      </a>
                      . If you believe that someone has created an unauthorized account depicting you or your likeness,
                      you can request its removal by flagging it. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Public Content:</span> Your contributions to the Service are
                      intended for public consumption and are therefore viewable by the public, including your photos,
                      ratings, reviews, tips, lists, Collections, compliments, Ask the Community posts, RiderDash Talk
                      posts, and edits to business listing information. Your account profile (e.g., first name, last
                      initial, city, neighborhood, month and year joined, profile photos and friends) is also intended
                      for public consumption, as is some of your other activity through the Service, like how you vote
                      on other people’s contributions (e.g., useful, funny, cool, helpful, not helpful), which
                      contributions you like, where you check-in or where your friends check-in on your behalf, which
                      contributions or users you follow, and which businesses you bookmark. You can limit the public
                      nature of some of these activities through your 
                      <a className="a-link" href="">
                        account settings
                      </a>
                      . 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Contacts:</span> You can invite others to join or become your
                      friend on RiderDash by providing us with their contact information, or by allowing us to access
                      your contacts from your computer, mobile device, or third party sites to select which individuals
                      you want to invite. If you allow us to access your contacts, we may transmit that information to
                      our servers long enough to process your invitations. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Communications:</span> When you sign up for an account or use
                      certain features, you are opting to receive messages from other users, businesses, and RiderDash.
                      You can manage some of your messaging preferences through your 
                      <a className="a-link" href="">
                        account settings
                      </a>
                      , but note that you cannot opt out of receiving certain administrative, transactional, or legal
                      messages from RiderDash. For example, if you make a reservation, order food, place yourself on a
                      waitlist, or request a quote from a business through the Service, we may send you messages about
                      your transaction using the contact information you provide, including through automated SMS text
                      messages to your phone number. We may also track your actions in response to the messages you
                      receive from us or through the Service, such as whether you deleted, opened, or forwarded such
                      messages. 
                      <span style={{ fontWeight: 'bold' }}>
                        If you exchange messages with others through the Service, we may store them in order to process
                        and deliver them, allow you to manage them, and we may review and disclose them in connection
                        with investigations related to use of the Service, as well as our efforts to improve the
                        Service.
                      </span>
                       We may not deliver messages that we believe are objectionable, such as spam messages, fraudulent
                      solicitations, or requests to exchange reviews for compensation. If you send or receive messages
                      through the Service via SMS text message, we may log phone numbers, phone carriers, and the date
                      and time that the messages were processed. Carriers may charge recipients for texts that they
                      receive. We may also store information that you provide through communications to us, including
                      from phone calls, letters, emails and other electronic messages, or in person. If you are a
                      representative of a business listed on RiderDash, we may contact you, including by phone or email,
                      using the contact information you provide us, make publicly available, or that we have on record
                      for your business. Our calls with you may be monitored and recorded for quality purposes. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Transactions:</span> If you initiate a transaction through
                      the Service, such as a reservation or purchase, we may collect and store information about you,
                      such as your name, phone number, address, email, and payment information (such as a credit card
                      number and expiration date), as well as any other information you provide to us, in order to
                      process your transaction, send communications about them to you, and populate forms for future
                      transactions. This information may be shared with third parties, and third parties may share such
                      information with us, for the same purposes. When you submit credit card numbers, we encrypt that
                      information using industry standard technology. If you write reviews about businesses with which
                      you transact through the Service, we may publicly display the fact that you transacted with those
                      businesses. For example, if you make a dinner reservation through the Service and write a review
                      about your experience, we may publicly display the fact that you made your dinner reservation
                      through the Service. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Activity:</span> We may store information about your use of
                      the Service, such as your search activity, the pages you view, the date and time of your visit,
                      businesses you call using our mobile applications, and reservations, purchases, or transactions
                      you make through the Service. We may also store information that your computer or mobile device
                      may provide to us in connection with your use of the Service, such as your browser type, type of
                      computer or mobile device, browser language, IP address, WiFi information such as SSID, mobile
                      carrier, phone number, unique device identifier, advertising identifier, location (including
                      geolocation, beacon based location, and GPS location), and requested and referring URLs and mobile
                      applications. We may also receive and store your location whenever our mobile applications are
                      running, including when running in the background, if you enable our mobile apps to access such
                      information in the course of using the Service. You may be able to limit or disallow our use of
                      certain location data through your device or browser settings, for example by adjusting the
                      "Location Services" settings for our applications in iOS privacy settings. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Different Devices:</span> You may access the Service through
                      different devices (e.g., your mobile phone or personal computer) and different platforms (e.g.,
                      the RiderDash website or RiderDash mobile apps). The information that we collect and store through
                      those different uses may be cross-referenced and combined, and your contributions through one
                      RiderDash platform will typically be similarly visible and accessible through all other RiderDash
                      platforms. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Legal Basis for Processing:</span> For residents of the EEA
                      and Switzerland only, our legal basis for collecting and using the information described above
                      will depend on the specific information concerned and the context in which we collect it. We,
                      however, will normally collect personal information from you only where we have your consent to do
                      so, where we need the personal information to perform a contract with you, or where the processing
                      is in our legitimate interests and not overridden by your data protection interests or fundamental
                      rights and freedoms. In some cases, we may also have a legal obligation to collect personal
                      information from you or may otherwise need the personal information to protect your vital
                      interests or those of another person (for instance, to prevent, investigate, or identify possible
                      wrongdoing in connection with the Service or to comply with legal obligations). If we ask you to
                      provide personal information to comply with a legal requirement or to perform a contract with you,
                      we will make this clear at the relevant time and advise you whether the provision of your personal
                      information is mandatory or not (as well as of the possible consequences if you do not provide
                      your personal information). If we collect and use your personal information in reliance on our
                      legitimate interests (or those of any third party), this interest will typically be to operate our
                      Services, communicate with you in relation to our Services, or for our other legitimate commercial
                      interests, for instance, when responding to your queries, to analyze and improve our platform,
                      engage in marketing, or for the purposes of detecting or preventing fraud. If you have questions
                      about or need further information concerning the legal basis on which we collect and use your
                      personal information, please contact us at the address listed below in the “Contact” section. 
                    </li>
                  </ol>
                  <li id="Cookies" className="terms_subtitle mt-30 mb-20">
                    COOKIES
                  </li>
                  <p className="terms_contents mt-10">
                    We, and third parties with whom we partner, may use cookies, web beacons, tags, scripts, local
                    shared objects such as HTML5 and Flash (sometimes called "flash cookies"), advertising identifiers
                    (including mobile identifiers such as Apple’s IDFA or Google’s Advertising ID) and similar
                    technology ("Cookies") in connection with your use of the Service, third party websites, and mobile
                    applications. Cookies may have unique identifiers, and reside, among other places, on your computer
                    or mobile device, in emails we send to you, and on our web pages. Cookies may transmit information
                    about you and your use of the Service, such as your browser type, search preferences, IP address,
                    data relating to advertisements that have been displayed to you or that you have clicked on, and the
                    date and time of your use. Cookies may be persistent or stored only during an individual session.
                    <br />
                    The purposes for which we use Cookies in the Service include:
                  </p>
                  <table className="policy_table mt-30 mb-20">
                    <thead className="policy_thead">
                      <tr>
                        <th className="font-weight-500">Purpose</th>
                        <th className="font-weight-500">Explanation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Processes</td>
                        <td>
                          Intended to make the Service work in the way you expect. For example, we use a Cookie that
                          tells us whether you have already signed up for an account.
                        </td>
                      </tr>
                      <tr>
                        <td>Authentication, Security, and Compliance</td>
                        <td>
                          Intended to prevent fraud, protect your data from unauthorized parties, and comply with legal
                          requirements. For example, we use Cookies to determine if you are logged in.
                        </td>
                      </tr>
                      <tr>
                        <td>Preferences</td>
                        <td>
                          Intended to remember information about how you prefer the Service to behave and look. For
                          example, we use a Cookie that tells us whether you have declined to allow us to send push
                          notifications to your phone.
                        </td>
                      </tr>
                      <tr>
                        <td>Notifications</td>
                        <td>
                          Intended to allow or prevent notices of information or options that we think could improve
                          your use of the Service. For example, we use a Cookie that stops us from showing you the
                          signup notification if you have already seen it.
                        </td>
                      </tr>
                      <tr>
                        <td>Advertising</td>
                        <td>
                          Intended to make advertising more relevant to users and more valuable to advertisers. For
                          example, we may use Cookies to serve you interest-based ads, such as ads that are displayed to
                          you based on your visits to other websites, or to tell us if you have recently clicked on an
                          ad.
                        </td>
                      </tr>
                      <tr>
                        <td>Analytics</td>
                        <td>
                          Intended to help us understand how visitors use the Service. For example, we use a Cookie that
                          tells us how our search suggestions correlate to your interactions with the search page.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="terms_contents mt-10">
                    You can set some Cookie preferences through your device or browser settings, but doing so may affect
                    the functionality of the Service. The method for disabling Cookies may vary by device and browser,
                    but can usually be found in your device or browser preferences or security settings. For example,
                    iOS and Android devices each have settings which are designed to limit forms of ad tracking. For
                    flash cookies, you can manage your privacy settings by clicking 
                    <a
                      className="a-link"
                      href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html#117118"
                    >
                      here.
                    </a>{' '}
                    Please note that changing any of these settings does not prevent the display of certain
                    advertisements to you. 
                  </p>
                  <li className="terms_subtitle mt-30 mb-20">THIRD PARTIES</li>
                  <p className="terms_contents mt-10">Third parties may receive information about you as follows:</p>
                  <ol className="pl-10 mt-10" type="a">
                    <li className="terms_contents mt-10">
                      <span style={{ fontWeight: 'bold' }}>Advertisers:</span> We may allow third parties to use Cookies
                      through the Service to collect the same type of information for the same purposes as we do. In
                      doing so, we adhere to the Digital Advertising Alliance’s Self-Regulatory Principles for Online
                      Behavioral Advertising. Third parties may be able to associate the information they collect with
                      other information they have about you from other sources. We do not necessarily have access to or
                      control over the Cookies they use, but you may be able to opt out of some of their practices by
                      visiting the following links: 
                      <a className="a-link" href="www.networkadvertising.org/choices/">
                        Network Advertising Initiative
                      </a>
                      , 
                      <a
                        className="a-link"
                        href="https://www.d1.sc.omtrdc.net/optout.html?omniture=1&popup=1&locale=en_US&second=1&second_has_cookie=0"
                      >
                        Omniture
                      </a>
                       and 
                      <a className="a-link" href="www.aboutads.info/choices/">
                        Digital Advertising Alliance
                      </a>
                      . Please note that opting out does not prevent the display of all advertisements to you.
                      Additionally, we may share non-personally identifiable information from or about you, such as
                      location data, advertising identifiers, or a cryptographic hash of a common account identifier
                      (such as an email address), with third parties in connection with advertising programs and data
                      analytics, including with third parties that help determine the efficacy of such advertising
                      programs by aggregating information they receive from multiple services like the Service. You may
                      be able to limit our sharing of some of this information through your mobile device settings, as
                      described in Section 2 above, or through the Service’s settings.
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Content Partners:</span> We allow third party partners to use
                      and display some of the public content available through the Service, such as your photos,
                      reviews, and other information detailed in Section 1(b) above. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Service Providers:</span> We may rely on third party
                      providers to support or provide some of the services that are available through the Service, such
                      as reservations and food delivery. We may also rely on third party providers to perform certain
                      services for us in connection with your use of the Service, such as communications and hosting
                      services, network security, technical and customer support, tracking and reporting functions,
                      quality assurance testing, payment processing, our own marketing of the Service, and other
                      functions. We may share information from or about you with these third party providers so that
                      they can perform their services or complete your requests. These third party providers may share
                      information with us that they obtain from or about you in connection with providing their services
                      or completing your requests. Third party providers may also share this information with their
                      subsidiaries, joint ventures, or other companies under common control. Some of our web pages
                      utilize framing techniques to serve content to you from our third party providers, while
                      preserving the look and feel of the Service. In such cases, please note that the information you
                      provide is being provided to the third party. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Aggregate or Anonymous Information:</span> We may share user
                      information in the aggregate with third parties, such as businesses that are listed on RiderDash
                      and content distributors. For example, we may disclose the number of users that have been exposed
                      to or clicked on advertisements. We may also disclose anonymized information about your use on
                      RiderDash. For example, if you engage in a transaction in connection with RiderDash, we may
                      publicly disclose information about the transaction without providing additional identifying
                      information about you or otherwise disclosing your participation in the transaction. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Business Transfers:</span> We may share information from or
                      about you with our parent companies, subsidiaries, joint ventures, or other companies under common
                      control, in which case we will require them to honor this Privacy Policy. If another company
                      acquires RiderDash, or all or substantially all of our assets, that company will possess the same
                      information, and will assume the rights and obligations with respect to that information as
                      described in this Privacy Policy. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Businesses on RiderDash:</span> We may share information from
                      or about you (such as your age, gender, and city), your devices, and your use of the Service (such
                      as which businesses you bookmark or call, or if you visit a business’s URL) with businesses listed
                      on RiderDash. You may adjust your 
                      <a className="a-link" href="">
                        account settings
                      </a>
                       to increase or decrease the amount of information we share. Keep in mind that businesses may
                      still see your public activity and posts, and may receive information from or about you when you
                      transact or communicate with them, through RiderDash or otherwise, regardless of your settings
                      (see Section 1 above). Additionally, if you make a phone call to a business through or in
                      connection with your use of the Service, we may share information about your call with the
                      business that the business would have received had you called them directly, such as the date and
                      time of your call and your phone number. You may be able to limit our ability to collect and share
                      your phone number through your phone’s settings or phone service provider. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Investigations and Legal Disclosures:</span> We may
                      investigate and disclose information from or about you if we have a good faith belief that such
                      investigation or disclosure: (a) is reasonably necessary to comply with legal processes and law
                      enforcement instructions and orders, such as a search warrant, subpoena, statute, judicial
                      proceeding, or other legal process or law enforcement requests served on us; (b) is helpful to
                      prevent, investigate, or identify possible wrongdoing in connection with the Service; or (c)
                      protects our rights, reputation, property, or that of our users, affiliates, or the public, such
                      as disclosures in connection with RiderDash’s Consumer Alerts program. If you flag or otherwise
                      complain to us about content through the Service, we may share the substance of your complaint
                      with the contributor of that content in order to provide an opportunity for the contributor to
                      respond. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Links:</span> The Service may link to third party services,
                      like a business’s URL. Except as set forth herein, we do not share your personal information with
                      them, and are not responsible for their privacy practices. We suggest you read the privacy
                      policies on or applicable to all such third party services. 
                    </li>
                    <li className="terms_contents">
                      <span style={{ fontWeight: 'bold' }}>Third Party Accounts:</span> If you sign up for, or log into,
                      RiderDash using a third party service like Facebook or Google, or link your RiderDash account to
                      your account with a third party service like Facebook, Instagram or Twitter, we may receive
                      information about you from such third party service. If you post content to a third party service
                      through the Service, that third party service will also receive that content, which will be
                      visible to anyone that has access to it through that third party service. 
                    </li>
                  </ol>
                  <li className="terms_subtitle mt-30 mb-20">CONTROLLING YOUR PERSONAL DATA</li>
                  <p className="terms_contents mt-10">
                    Other users may be able to identify you, or associate you with your account, if you include personal
                    information in the content you post publicly. You can reduce the risk of being personally identified
                    by using the Service pseudonymously, though doing so could detract from the credibility of your
                    contributions to the Service. RiderDash users can also use the Find Friends feature to find one
                    another based on their names or email addresses. You can adjust the settings for this feature in
                    your 
                    <a className="a-link" href="">
                      account settings
                    </a>
                    . Please also note that the messages you send or receive using the Service are only private to the
                    extent that both you and the person you are communicating with keep them private. For example, if
                    you send a message to another user, that user may choose to publicly post it. Also, we may access,
                    review, and disclose such messages in connection with investigations related to use of the Service,
                    as well as our efforts to improve the Service. If you are a resident of the EEA or Switzerland, you
                    have the right to access, correct, update or request deletion of your personal information. You can
                    object to the processing of your personal information, ask us to restrict processing of your
                    personal information or request portability of your personal information. Similarly, if we have
                    collected and processed your personal information with your consent, then you can withdraw your
                    consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we
                    conducted prior to your withdrawal, nor will it affect processing of your personal information
                    conducted in reliance on lawful processing grounds other than consent. You also have the right to
                    opt-out of marketing communications we send you at any time. The Service generally provides you with
                    a reasonable means to view and change your profile information and you can opt-out of marketing
                    communications at any time by clicking on the “unsubscribe” or “opt-out” link in the marketing
                    emails we send you or in your 
                    <a className="a-link" href="">
                      account settings
                    </a>
                    . However, if you have any questions or concerns about the processing of your personal information,
                    you may contact us as outlined in the “Contact” section below. Please note that the “data
                    controller” responsible for protecting your personal data related to the Service is: (i) if you are
                    a resident of the United States or Canada, RiderDash Inc., a Delaware corporation with its
                    headquarters in San Francisco, California; or (ii) if you are a resident in the EEA or anywhere else
                    outside of the United States or Canada, RiderDash Ireland Ltd., a limited liability company
                    established and resident under the laws of the Republic of Ireland. 
                  </p>
                  <li className="terms_subtitle mt-30 mb-20">DATA RETENTION AND ACCOUNT TERMINATION</li>
                  <p className="terms_contents">
                    You can close your account by clicking <a className="a-link">here</a>. We will remove certain public
                    posts from view and/or dissociate them from your account profile, but we may retain information
                    about you for the purposes authorized under this Privacy Policy unless prohibited by law. For
                    example, we may retain information to prevent, investigate, or identify possible wrongdoing in
                    connection with the Service or to comply with legal obligations. Please note that businesses cannot
                    remove their business listings, ratings, or reviews by closing their accounts.
                  </p>
                  <li className="terms_subtitle mt-30 mb-20">CHILDREN</li>
                  <p className="terms_contents mt-10">
                    The Service is intended for general audiences and is not directed to children under 13. We do not
                    knowingly collect personal information from children under 13. If you become aware that a child has
                    provided us with personal information without parental consent, please contact us 
                    <a className="a-link" href="">
                      here
                    </a>
                    . If we become aware that a child under 13 has provided us with personal information without
                    parental consent, we take steps to remove such information and terminate the child's account. 
                  </p>
                  <li className="terms_subtitle mt-30 mb-20">SECURITY</li>
                  <p className="terms_contents mt-10">
                    We use various safeguards to protect the personal information submitted to us, both during
                    transmission and once we receive it. However, no method of transmission over the Internet or via
                    mobile device, or method of electronic storage, is 100% secure. Therefore, while we strive to use
                    commercially acceptable means to protect your personal information, we cannot guarantee its absolute
                    security.
                  </p>
                  <li className="terms_subtitle mt-30 mb-20">CONTACT</li>
                  <p className="terms_contents mt-10">
                    You may 
                    <a className="a-link" href="">
                      contact us online
                    </a>
                     concerning our Privacy Policy, or write to us at the following address:
                  </p>
                  <p className="terms_contents mt-10">
                    RiderDash, Inc. 
                    <br />
                    3003 N 1st St.  <br />
                    Unit 221 San Jose CA 95134
                  </p>
                  <p className="terms_contents mt-10">
                    We will take such steps as we deem necessary to confirm your identity before sharing any personal
                    data with you. We will respond to proper and confirmed requests relating to personal data within 30
                    days, or as otherwise required by applicable law.
                  </p>
                  <li id="Policy" className="terms_subtitle mt-30 mb-20">
                    MODIFICATIONS TO THIS PRIVACY POLICY
                  </li>
                  <p className="terms_contents mt-10">
                    We may revise this Privacy Policy from time to time. The most current version of the Privacy Policy
                    will govern our collection, use, and disclosure of information about you and will be located here.
                    If we make material changes to this Privacy Policy, we will notify you by email or by posting a
                    notice on the Service prior to or on the effective date of the changes. By continuing to access or
                    use the Service after those changes become effective, you acknowledge the revised Privacy Policy. 
                  </p>
                  <li id="Rights" className="terms_subtitle mt-30 mb-20">
                    CALIFORNIA RESIDENTS: YOUR CALIFORNIA PRIVACY RIGHTS
                  </li>
                  <p className="terms_contents mt-10">
                    We do not disclose your personal information to third parties for the purpose of directly marketing
                    their goods or services to you unless you first agree to such disclosure. If you have any questions
                    regarding this policy, or would like to change your preferences, you may contact us at the address
                    listed above in Section 8.
                  </p>
                  <li id="Transfer" className="terms_subtitle mt-30 mb-20">
                    IN TERNATIONAL DATA T RANSFER
                  </li>
                  <p className="terms_contents mt-10">
                    For residents of the EEA or Swi tzerland, please note that the personal data information we obtain
                    from or about you may be transferred, processed and stored outside of the EEA or Switzerland for the
                    purposes described in this Privacy Policy, including in the United States of America. We take the
                    privacy of our users seriously and therefore take steps to safeguard your information, including
                    ensuring an adequate level of data protection in accordance with E.U. standards. These include
                    implementing the European Commission’s Standard Contractual Clauses for transfers of personal
                    information between our group companies, which require all group companies to protect personal
                    information they process from the EEA in accordance with European Union data protection laws. We
                    have implemented similar appropriate safeguards with our third party service providers and partners
                    and further details can be provided upon request.
                  </p>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default Terms
