export const getfilteredData = (data, filters, i = 0) => {
  const filterKeys = Object.keys(filters)
  if (i > filterKeys.length - 1) {
    return data
  }
  const key = filterKeys[i]
  const value = filters[key]
  if (!value) {
    return getfilteredData(data, filters, i + 1)
  }

  const filteredData = data.filter(d => {
    // delete unused key
    return d[key] === value
  })
  return getfilteredData(filteredData, filters, i + 1)
}

export const deletedData = data =>
  data.map(d => {
    delete d['Coupe / Sedan / Small suv / Mid-Large suv / van']
    return d
  })

export const getGroupByData = (data, key) => {
  if (!data.length) {
    return data
  }
  const keys = Object.keys(data[0])

  if (keys.indexOf(key) === -1) {
    return []
  }

  return data.reduce((pre, cur) => {
    if (pre.indexOf(cur[key]) === -1) {
      pre.push(cur[key])
    }
    return pre
  }, [])
}

export const loadCarDB = (filters = {}) => {
  const data = require('./info_cars.json')
  return deletedData(getfilteredData(data, filters))
}

export const loadProductsDB = (filters = {}) => {
  const data = require('./info_products.json')
  return getfilteredData(data, filters)
}
