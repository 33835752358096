import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

import quoteReducer from './quote'
import shopeReducer from './shope'
import homeReducer from './home'

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  quote: quoteReducer,
  shope: shopeReducer,
  home: homeReducer,
})

export default rootReducer
