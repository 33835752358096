export const SAVE_QUOTE = 'SAVE_QUOTE'
export const UPDATE_QUOTE = 'UPDATE_QUOTE'
export const GET_QUOTE = 'GET_QUOTE'
export const GET_CHAT_TOKEN = 'GET_CHAT_TOKEN'
export const SEARCH_SHOPE = 'SEARCH_SHOPE'
export const CLEAR_QUOTE = 'CLEAR_QUOTE'
export const SET_HOMEPATH = 'SET_HOMEPATH'
export const SET_SELECTED_STORE_INDEX = 'SET_SELECTED_STORE_INDEX'
export const SET_SELECTED_STORE = 'SET_SELECTED_STORE'
export const SET_VERTEX_STORES = 'SET_VERTEX_STORES'
