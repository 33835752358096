import { applyMiddleware, createStore, compose } from 'redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'
import { reduxFirestore, getFirestore } from 'redux-firestore'

import thunkMiddleware from 'redux-thunk'

import rootReducer from '../reducers'

const firebaseConfig = {
  apiKey: 'AIzaSyBMQobzI1bWsv3EsxkUqkpw9RnBakzG4UA',
  authDomain: 'llumar-marketplace-v1-205b1.firebaseapp.com',
  databaseURL: 'https://llumar-marketplace-v1-205b1.firebaseio.com',
  projectId: 'llumar-marketplace-v1-205b1',
  storageBucket: 'llumar-marketplace-v1-205b1.appspot.com',
  messagingSenderId: '518477308809',
} // from Firebase Console

const rfConfig = {
  useFirestoreForProfile: false,
} // optional redux-firestore Config Options

// Initialize firebase instance
firebase.initializeApp(firebaseConfig)
// Initialize Cloud Firestore through Firebase
firebase.firestore()
// Set Anonymously user login
firebase.auth().signInAnonymously()

const createStoreWithFirebase = compose(
  reduxFirestore(firebase, rfConfig), // firebase instance as first argument, rfConfig as optional second
  reactReduxFirebase(firebase, rfConfig),
  applyMiddleware(thunkMiddleware.withExtraArgument({ getFirestore, getFirebase })),
)(createStore)

const initialState = {}

const store = createStoreWithFirebase(rootReducer, initialState)
export default store
