import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import Header from './Header'
import Header2 from './Header_2'
import Footer from './Footer'

class Layout extends Component {
  render() {
    if (!isMobile) {
      return (
        <div>
          <Header2 />
          <div style={{ paddingBottom: 217 }}>{this.props.children}</div>
          <Footer />
        </div>
      )
    } else {
      return (
        <div>
          <Header />
          <div style={{ paddingBottom: 217 }}>{this.props.children}</div>
          <Footer />
        </div>
      )
    }
  }
}

export default Layout
