import React from 'react'

const Footer = props => (
  <div className="footer_container">
    <footer>
      <div className="container text-white mt-10 mb-10">
        <div className="footer_group_container">
          <div className="footer_group text-white mb-50">
            <ul>
              <li className="footer_title mb-10">ABOUT</li>
              <li className="footer_list">
                <a
                  href="https://www.riderdash.com"
                  className="about text-white text-left"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RiderDash
                </a>{' '}
              </li>
            </ul>
          </div>
          <div className="footer_group text-white mb-50">
            <ul>
              <li className="footer_title mb-10">HELP</li>
              <li className={'footer_list'}>
                <a className="terms text-white" href="/Terms/#terms" target="_blank">
                  Terms of Use
                </a>{' '}
              </li>
              <li className={'footer_list mt-10'}>
                <a className="privacy text-white" href="/Terms/#policy" target="_blank">
                  Privacy Policy
                </a>{' '}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p className="footer_copyright text-center text-white">© 2019 RiderDash, Inc.</p>
    </footer>
  </div>
)

export default Footer
