import React, { Component } from 'react'
import { zendeskOpen, updatePath } from '../../utils/zendesk'

class Chatbot extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  zendeskShow() {
    window.zE('webWidget', 'show')
    this.chatStartZendesk()
  }

  chatStartZendesk() {
    updatePath('TEST SESSION')

    window.zE('webWidget', 'chat:send', 'TEST SESSION START', function(err) {})
    setTimeout(() => zendeskOpen(), 500)
  }

  render() {
    return (
      <div>
        <h1>RIDERDASH CHATBOT TEST</h1>
        <button className="btn_black btn_lg" onClick={() => this.zendeskShow()}>
          CHAT START
        </button>
      </div>
    )
  }
}

export default Chatbot
