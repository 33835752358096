import { sendGA, sendNabusScript } from '../redux/actions'
import history from './history'
import zChat from '../vendor/web-sdk'

zChat.init({
  account_key: 'F6QSy30CSi1rLtDYZ4mx5umt67yxAy5s',
})

export const getStatus = () => {
  let status = ''

  zChat.getAllDepartments().forEach(dep => {
    if (dep.name === 'agent') {
      status = dep.status
    }
  })

  return status
}

const AGENT_ANSWER_TEXT = '네, 알려주신 번호로 진행해 드리겠습니다. 감사합니다!'
// const CHATBOT_END_TEXT = '그러면 제가 고객님께 맞는 전문 상담원을 바로 연결해 드리겠습니다. ^^'
// const CHATBOT_FORCE_END = '바로 전문 상담원을 연결해 드리겠습니다. ^^'
// const CHATBOT_HANDOVER_TEXT = '🚕 상담원 배정중... 🚗'

var tunning_point_flag = false
// var chatbot_handover_flag = false
function getChatLog() {
  if (tunning_point_flag) return
  window.zE(function() {
    var ifEles = document.getElementsByTagName('iframe')
    for (var x = 0; x < ifEles.length; x++) {
      // An error occurred due to a problem with some iframe permissions.
      // So if (iframe.id) !== ("webwidget") continue
      if (ifEles[x].id !== 'webWidget') continue
      var zdDoc = ifEles[x].contentDocument
      if (zdDoc) {
        // zendesk chat log class name
        var els = zdDoc.querySelectorAll('span.Linkify')
        for (var y = 0; y < els.length; y++) {
          // Handover
          // if (CHATBOT_END_TEXT === els[y].innerText || CHATBOT_FORCE_END === els[y].innerText) {
          //   if (getStatus() === 'online' && !chatbot_handover_flag) {
          //     setTimeout(() => {
          //       window.zE('webWidget', 'chat:send', CHATBOT_HANDOVER_TEXT, function(err) {})
          //     }, 2500)
          //     chatbot_handover_flag = true
          //   }
          // }

          // Get Phone Number
          if (AGENT_ANSWER_TEXT === els[y].innerText) {
            tunning_point_flag = true
            sendGA({ category: 'After Quote', action: 'Get Phone Number' })
            sendNabusScript(-1, 'E4494C03')
            history.push('/consulting/get_phone_number')
          }
        }
      }
    }
  })
}

var mutationObserver = new MutationObserver(function(mutations) {
  if (tunning_point_flag) return
  getChatLog()
})

mutationObserver.observe(document.documentElement, {
  attributes: true,
  characterData: true,
  childList: true,
  subtree: true,
  attributeOldValue: true,
  characterDataOldValue: true,
})

export const zendeskShow = () => {
  // window.$zopim.livechat.button.show()
  window.zE('webWidget', 'show')
}

export const zendeskOpen = () => {
  window.zE('webWidget', 'open')
  removeZendeskLogo()
}

export const updatePath = title => {
  window.zE('webWidget', 'updatePath', {
    title: title,
  })
}

export const removeZendeskLogo = () => {
  window.zE(function() {
    var ifEles = document.getElementsByTagName('iframe')

    for (var x = 0; x < ifEles.length; x++) {
      // An error occurred due to a problem with some iframe permissions.
      // So if (iframe.id) !== ("webwidget") continue
      if (ifEles[x].id !== 'webWidget') continue

      var zdDoc = ifEles[x].contentDocument
      if (zdDoc) {
        // - zendesk logo class names -
        // logo-2eLrO
        // u-linkClean
        // formSuccess-1uKiY
        // zendeskLogo-3IhSp
        var els = zdDoc.querySelectorAll('a.icon-2_pL9')
        // console.log(els)
        if (els.length === 1) {
          els[0].style.display = 'none'
        }
      }
    }
  })
}

export const zendeskSendInTint = async quote => {
  updatePath('tint')

  const customerCar = quote.quote.car

  var startMessage =
    '[상담 내용 요약] \n' +
    '• 시공 차량 : ' +
    customerCar.origin +
    ' ・ ' +
    customerCar.maker +
    ' ・ ' +
    customerCar.model

  if (getStatus() === 'online') {
    window.zE('webWidget', 'chat:send', startMessage, function(err) {})
  }

  setTimeout(() => zendeskOpen(), 500)
}
