const CarType = type => {
  if (type === 'coupe') return 'sedan'
  return type
}

const CarOrigin = origin => {
  if (origin !== '대한민국') return 'other'

  return 'korea'
}

/*const BackRates = {
  2: 0.5,
  4: 0.4,
  6: 0.3
}*/

export const SunroofType = {
  없음: '',
  썬루프: 'sunroof_normal',
  파노라마: 'sunroof_panorama',
}

export const getPriceKey = (car, key, type) => {
  if (['side', 'back'].indexOf(key) !== -1) {
    if (type === 'all') {
      return `price_all_${CarOrigin(car.origin)}_${CarType(car.type)}`
    } else {
      return `price_part_${key}_${CarOrigin(car.origin)}`
    }
  } else {
    if (key.startsWith('sunroof')) {
      return `price_${key}_${CarOrigin(car.origin)}`
    } else {
      return `price_${key}_${CarOrigin(car.origin)}_${CarType(car.type)}`
    }
  }
}

export const getCarInfoType = (productStatus, checkIndexs) => {
  return checkIndexs.every(i => productStatus[i].selected) ? 'all' : 'part'
}

export const updateInfo = info => {}
