import { SAVE_QUOTE, UPDATE_QUOTE, GET_QUOTE, CLEAR_QUOTE } from '../actionTypes'

import { quote } from '../scheme'

export const initialState = {
  quote,
  quote_id: null,
  info: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_QUOTE:
      return {
        ...initialState,
      }
    case SAVE_QUOTE:
      return {
        ...state,
        quote: action.payload.quote,
        car: action.payload.quote.car,
        info: action.payload.quote.info,
        quote_id: action.payload.quote_id,
      }
    case UPDATE_QUOTE:
      return {
        ...state,
        quote: action.payload,
        car: action.payload.car,
        info: action.payload.info,
        quote_id: action.payload.quote_id,
      }
    case GET_QUOTE:
      return {
        ...state,
        quote: action.payload,
        car: action.payload.quote.car,
        info: action.payload.quote.info,
      }
    default:
      return {
        ...state,
      }
  }
}
