import React from 'react'
import PropTypes from 'prop-types'

import low from '../../image/product/low.png'

import guide_1 from '../../image/tint/guide_1.gif'
import guide_1_m from '../../image/tint/guide_1_m.gif'
import guide_2 from '../../image/tint/guide_2.gif'
const Guide = ({ title }) => (
  <div className="tint_guide_button_pos">
    <div className="btn tint_guide_btn shadow-sm" data-toggle="modal" data-target="#tint_guide">
      <i className="fa fa-question-circle"></i> {title}
    </div>
    <div className="modal fade" id="tint_guide" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal_close" data-dismiss="modal">
            <img src={low} alt="" widht="20" />
          </div>
          <div className="modal-body">
            <img src={guide_1} alt="" className="hidden-sm-down" />
            <img src={guide_1_m} alt="" className="hidden-md-up" />
            <img src={guide_2} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)

Guide.propTypes = {
  title: PropTypes.string,
}

export default Guide
