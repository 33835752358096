import axios from 'axios'

// export const MSGCENTER = 'http://localhost:3000/'
export const MSGCENTER = 'https://api.riderdash.com/'

export const sendSlack = data => axios.post(`${MSGCENTER}send/marketplace/consulting_req_to_send`, data)
export const sendAlertToSlack = data => axios.post(`${MSGCENTER}send/marketplace/send_alert_to_slack`, data)

export const sendCouponCodeToSlack = data => axios.post(`${MSGCENTER}send/marketplace/send_coupon_code`, data)

// //////////
// Naver Geolocation
export const geolocation = data => {
  return axios.post(`${MSGCENTER}send/marketplace/geolocation`, data).then(res => res.data)
}

// //////////
// Kakao Reverse Geocoding
export const reverseGeocoding = data => {
  return axios.post(`${MSGCENTER}send/marketplace/reverse_geocoding`, data).then(res => res.data)
}

// shops page store information from db
export const getParticipatedStores = data => axios.get(`${MSGCENTER}db/participated_stores`, data)
