import { SET_HOMEPATH } from '../actionTypes'

export const initialState = {
  homePath: -1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOMEPATH:
      return {
        ...state,
        homePath: action.homePath,
      }
    default:
      return { ...state }
  }
}
